import React from "react"
import Layout from "../components/layout"
import resume from "../files/Resume_TaoZhang.pdf"
import pdf from "../images/file-text-16.svg"

const AboutPage = () => {
  return (
    <Layout>
      <a class="download-link" href={resume}>
        <img src={pdf} alt="" class="pdf-icon" />
        Resume
      </a>
      <div class="about-wrapper">
        <p class="summary">
          UX designer skilled in design, user research, data analysis, and web
          accessibility. Analytical thinker focusing on the human element in
          complex systems.
        </p>
        <div class="two-col-grid">
          <div>
            <h2>Experience</h2>
            <section>
              <h3 className="title">UX Designer</h3>
              <p className="positionMeta">
                05/2021 - Present <span>&#183;</span> Amazon Web Services (AWS)
              </p>
              <ul>
                <li>
                  End-to-end UX design for an integrated new product for developer experiences.
                </li>
                <li>
                  Provide design support for multiple product and engineering teams in an agile environment.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="title">Senior Product Designer</h3>
              <p className="positionMeta">
                10/2020 - Present <span>&#183;</span> Esri
              </p>
              <ul>
                <li>
                  Designed UX framework, widgets, component guidelines for
                  ArcGIS Experience Builder.
                </li>
                <li>
                  Led UX design process from research, design, test, to
                  release.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="title">UX Designer</h3>
              <p className="positionMeta">
                10/2019 - 10/2020 <span>&#183;</span> Amazon Web Services (AWS)
              </p>
              <ul>
                <li>
                  Designed key components of AWS Activate, including user-facing
                  web apps, internal tools, and service experiences.
                </li>
                <li>
                  Worked closely with product management, engineering, and
                  operations to continuously monitor and improve Activate user
                  experience and key metrics.
                </li>
                <li>Led UX research on AWS startup customers.</li>
              </ul>
            </section>
            <section>
              <h3 className="title">Senior UX Architect</h3>
              <p className="positionMeta">
                04/2016 – 09/2019 <span>&#183;</span> Esri
              </p>
              <ul>
                <li>
                  Designed features in various areas of ArcGIS Online, Esri’s
                  web GIS platform used by over 7 million users.
                </li>
                <li>
                  Designed responsive, accessible web components for Esri SasS
                  products as a core contributor of Calcite Web, Esri’s design
                  system.
                </li>
                <li>
                  Mentored junior designers; built user research, design, and
                  testing processes as team scaled from 3 to 10 members in one
                  year.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="title">Digital User Experience Specialist</h3>
              <p className="positionMeta">
                10/2011 – 03/2016 <span>&#183;</span> Purdue University
              </p>
              <ul>
                <li>
                  Conducted UX research on library’s web presence to understand
                  academic users’ information seeking behavior.
                </li>
                <li>Redesigned Purdue University Libraries website.</li>
              </ul>
            </section>
            <section>
              <h3 className="title">Research Associate</h3>
              <p className="positionMeta">
                09/2009 – 10/2011 <span>&#183;</span> Vanderbilt University
              </p>
              <ul class="last-exp">
                <li>
                  Designed task flows and UI components for humans working with
                  multiple robots in emergency response teams.
                </li>
                <li>
                  Developed computational models of task workload in victim
                  assessment and area search scenarios in human-robot teams.
                </li>
              </ul>
            </section>
          </div>
          <div>
            <div>
              <h2>Education</h2>
              <ul className="education-skill">
                <li>
                  <p>
                    <strong>Ph.D.</strong>, 2009
                  </p>
                  <p>Human Factors </p> <p>North Carolina State University</p>
                </li>
                <li>
                  <p>
                    <strong>M.S.</strong>, 2006
                  </p>
                  <p>Human-Computer Interaction </p>
                  <p> Tsinghua University, China</p>
                </li>
                <li>
                  <p>
                    <strong>B.S.</strong>, 2004
                  </p>
                  <p>Industrial Engineering </p>
                  <p> Tsinghua University, China</p>
                </li>
              </ul>
            </div>
            <div>
              <h2>Skills</h2>
              <ul>
                <li>Sketch, HTML, CSS, JavaScript</li>
                <li>UX research and data analysis</li>
                <li>WCAG and Section 508</li>
                <li>Cross-disciplinary collaboration</li>
              </ul>
            </div>
            <div>
              <h2>Contact</h2>
              <ul>
                <li>Phone: +1-919-448-5042</li>
                <li>Email: zhangtao2000@gmail.com</li>
                <li>Website: jimmieego.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
